import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withAlert from '../../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../../common';
import { formConfig, prepareSkuLinesForValidation, updateSkuLine } from './config';
import { TableView, TotalView } from './View';
import Form from './Form';
import PromotionForm from '../../../orderProcessing/received/detail/promotion/PromotionForm';
import { getUser } from '../../../../../data/services';
import { totalPriceMapper } from '../../create/config';
import { calculateRelativeDiscount, totalViewRefs } from '../../../../inventory/purchaseReturn/create/table/config';
import {
  compareList,
  extractSingleItemFromList,
  filterItems,
  findAndReplaceItem,
} from '../../../../../utils/arrayProcessor';
import { getFlattenedOrders, isError, updateSkuUsageDates } from '../../../../common/HelperFunctions';
import { DISCOUNT_ID, ERROR, STOCK_TYPE_LIST } from '../../create/table/config';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { refValidator } from '../../../../../utils/refGenerator';
import { VAT_AMOUNT } from '../../../../../data/enums/GeneralConstants';
import { RETURN_TYPE } from '../../../../common/DomainConfig';
import TableStyled from '../../create/table/TableStyled';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  displayAlert: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  getDetails: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  skuList: PropTypes.arrayOf(Object),
};

const defaultProps = {
  data: {
    orders: [],
    amount: {},
  },
  skuList: [],
  enableErrorDisplay: false,
  serverResponseWaiting: false,
};

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        orders: [],
        amount: {},
      },
      dialog: {
        type: '',
        element: '',
      },
      selectedOrders: [],
      skuBatchList: [],
      validatedOrders: [],
      totalAmount: totalPriceMapper({}),
      distributorId: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    if (!compareList(data.orders, prevState.data.orders)) {
      this.updateState({
        totalAmount: totalPriceMapper({}),
        selectedOrders: [],
      });
    }
  }

  onAPIRequestFailure = () => {
    alert('API request failed');
  };

  getSKUBatchList = (skuId, distributorId) => {
    const { getSKUBatchDetail } = this.props;
    getSKUBatchDetail(
      {
        distributorId,
        skuId,
      },
      {
        handleSuccess: response => {
          this.setState({ skuBatchList: response.data.getSkuBatchDetails });
        },
        handleError: err => {
          // TODO call the api error function.
          // this.onAPIRequestFailure(err);
        },
      },
    );
  };

  onDropDownChange = (item, value, state, stateUpdater) => {
    const { skuList } = this.props;
    const { skuBatchList } = this.state;
    if (item === 'skuId') {
      const selectedSku = filterItems(skuList, value)[0] || {};
      // eslint-disable-next-line no-param-reassign
      state.amountDetails.rate = selectedSku.Rates[0].priceDetails.rlp;
      stateUpdater(state);
      const user = getUser();
      this.getSKUBatchList(value, user.Distributor[0].id);
    }
    if (item === 'skuBatchId') {
      const selectedBatch = skuBatchList.find(({ id }) => id === value);

      const usageDate = {
        manufacture: selectedBatch ? selectedBatch.manufactureDate : new Date().toISOString().slice(0, 10),
        expiry: selectedBatch ? selectedBatch.expiryDate : new Date().toISOString().slice(0, 10),
      };
      updateSkuUsageDates(state, stateUpdater, usageDate);
    }
  };

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element: {
          ...element,
          amountDetails: {
            ...element.amountDetails,
            discountTypeId: element?.amountDetails?.discountTypeId || DISCOUNT_ID.DISCOUNT_RS,
          },
        },
      },
    });
    const user = getUser();
    this.getSKUBatchList(element.SKU.id, user.Distributor[0].id);
  };

  inputDiscountCalc = (param, value, data, stateSetter, discounTypePercentStatus, total) => {
    if (discounTypePercentStatus) {
      const formattedValueModified = value <= 100 ? value : 0;
      data.amountDetails.discountValue = Number(formattedValueModified);
      data.amountDetails.promotionDiscount = (Number(formattedValueModified) / 100) * total;
    } else {
      const formattedValueModified = value <= total ? value : 0;
      data.amountDetails.discountValue = Number(formattedValueModified);
      data.amountDetails.promotionDiscount = Number(formattedValueModified);
    }
    stateSetter(data);
  };

  handleQtyChange = async (param1, returnqty, currentOrder, stateSetter) => {
    const discounTypePercentStatus = currentOrder.amountDetails.discountTypeId === DISCOUNT_ID.DISCOUNT_PER;
    const total = currentOrder.amountDetails.rate * currentOrder.returnQuantity;
    currentOrder.amountDetails.discountValue = 0;
    currentOrder.amountDetails.promotionDiscount = 0;
    stateSetter(currentOrder);
    param1 === 'discountValue' &&
      this.inputDiscountCalc(param1, returnqty, currentOrder, stateSetter, discounTypePercentStatus, total);
  };

  getDiscount = currentOrder => {
    const { data } = this.state;
    const Line = data.lines.find(item => item.id === currentOrder.id);
    const referenceAmount = Line.amountDetails.rate * Line.quantity;
    const referenceDiscount = Number(Line.amountDetails.promotionDiscount) + Number(Line.amountDetails.topUpDiscount);
    const amount = currentOrder.amountDetails.rate * currentOrder.quantity;
    const discount = calculateRelativeDiscount(referenceAmount, referenceDiscount, amount);
    return discount;
  };

  getDiscountValidation = async currentOrder => await this.validatePromotion(currentOrder).then(order => order);

  handlePrimaryCheckBoxClick = event => {
    const { data } = this.state;
    let selectedOrdersList = [];
    let flattenedOrderList = [];
    let validatedOrders = [];
    if (event.currentTarget.checked) {
      selectedOrdersList = data.orders.map(order => order.id) || [];
      flattenedOrderList = data.orders;
    } else {
      selectedOrdersList = [];
    }
    if (flattenedOrderList.length > 0) {
      validatedOrders = data.orders.map(order => prepareSkuLinesForValidation(order) || {});
    }
    this.setState(
      {
        selectedOrders: selectedOrdersList,
        validatedOrders,
      },
      () => {
        this.calculateLineTotal();
      },
    );
  };

  handleSecondaryCheckboxClick = async (order, checked = false) => {
    const { selectedOrders } = this.state;
    const index = selectedOrders.indexOf(order.id);
    let clickSuccessful = false;
    let validatedOrder = order;
    if (index > -1) {
      if (order.promotionIds.length > 0) {
        if (order.quantity === order.returnQuantity) {
          await this.getDiscountValidation(order).then(modifiedOrder => {
            validatedOrder = { ...(modifiedOrder || {}) };
            if (modifiedOrder.valid) {
              !checked && selectedOrders.splice(index, 1);
              clickSuccessful = true;
            }
          });
        } else {
          !checked && selectedOrders.splice(index, 1);
          clickSuccessful = true;
        }
      } else {
        !checked && selectedOrders.splice(index, 1);
        clickSuccessful = true;
      }
    } else if (order.promotionIds.length > 0) {
      if (order.quantity === order.returnQuantity) {
        await this.getDiscountValidation(order).then(modifiedOrder => {
          validatedOrder = { ...(modifiedOrder || {}) };
          if (modifiedOrder.valid) {
            selectedOrders.push(order.id);
            clickSuccessful = true;
          }
        });
      } else {
        selectedOrders.push(order.id);
        clickSuccessful = true;
      }
    } else {
      clickSuccessful = true;
      selectedOrders.push(order.id);
    }
    this.setState({ selectedOrders }, () => {
      this.calculateLineTotal();
      if (clickSuccessful) this.updateValidatedOrder(validatedOrder);
    });
  };

  handleDiscountChange = e => {
    const { totalAmount } = this.state;
    totalAmount[e.target.name] = e.formattedValue;
    this.calculateTotal([], totalAmount);
  };

  handleFormSubmit = (type, dialogData) => {
    this.handleDataUpdate(type, dialogData);
    this.calculateLineTotal();
  };

  handleRowDelete = dialogData => {
    const { data } = this.state;
    const orderIndex = data.orders.findIndex(item => item.promotionId === dialogData.promotionId);
    const index = data.orders[orderIndex].Lines.findIndex(item => item.id === dialogData.id);
    data.orders[orderIndex].Lines.splice(index, 1);
    this.setState({ data }, () => this.calculateLineTotal());
  };

  handleDataUpdate = (type, dialogData) => {
    const { data } = this.state;
    const modifiedDialogData = this.modifyData(dialogData);
    if (modifiedDialogData.id) {
      const index = data.orders.findIndex(order => order.id === dialogData.id);
      data.orders[index] = modifiedDialogData;
    } else {
      modifiedDialogData.id = 1000 + (data.orders.length > 0 ? (data.orders[0] ? data.orders[0].length + 1 : 1) : 1);
      data.orders = this.encapsulateSkuLineToPromotionOrder([...data.orders], modifiedDialogData);
    }
    this.setState(
      { data },
      // () => this.handleSecondaryCheckboxClick(modifiedDialogData, true)
    );
  };

  updateValidatedOrder = currentOrder => {
    const { validatedOrders } = this.state;
    const newValidatedOrder = [...(validatedOrders || [])];
    const index = newValidatedOrder.findIndex(item => item.orderId === currentOrder.id);
    const formattedOrder = prepareSkuLinesForValidation(currentOrder) || {};
    if (index > -1) {
      newValidatedOrder.splice(index, 1, formattedOrder);
    } else {
      newValidatedOrder.push(formattedOrder);
    }

    this.setState({ validatedOrders: newValidatedOrder });
  };

  encapsulateSkuLineToPromotionOrder = (orders, dialogData) => {
    if (orders.length === 0) {
      return [
        {
          id: null,
          promotionId: null,
          Lines: [
            {
              ...dialogData,
              promotionId: null,
            },
          ],
        },
      ];
    }
    orders[0].Lines.push({
      ...dialogData,
      promotionId: null,
    });

    return orders;
  };

  validatePromotion = async currentOrder => {
    const { validateSrnData, displayAlert } = this.props;
    const validationObj = this.prepareDataForValidation(currentOrder) || {};

    return new Promise((resolve, reject) => {
      validateSrnData(
        {
          input: { ...validationObj },
        },
        {
          handleSuccess: response => {
            const responseData = response.data.validateSrnData;
            if (responseData) {
              if (responseData.status) {
                resolve({
                  ...currentOrder,
                  amountDetails: responseData.Data.amountDetails || {},
                  valid: true,
                });
              } else {
                displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Violates Discount Criteria');
                resolve({
                  ...currentOrder,
                  returnQuantity: currentOrder.returnQuantity,
                  valid: false,
                });
              }
            } else {
              if (isError(response)) {
                displayAlert(ALERT_TYPE.CUSTOM_DANGER, response.errors[0].message);
              }
              resolve({
                ...currentOrder,
                returnQuantity: currentOrder.quantity,
                valid: false,
              });
            }
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
            resolve({
              ...currentOrder,
              returnQuantity: currentOrder.returnQuantity,
              valid: false,
            });
          },
        },
      );
    });
  };

  prepareDataForValidation = currentOrder => {
    const { data, validatedOrders } = this.state;
    const preparedData = {
      triggeredObj: prepareSkuLinesForValidation(currentOrder),
      invoiceNumber: data.salesInvoiceNumber,
      editedObj: validatedOrders.filter(order => order.orderId !== currentOrder.orderId) || [],
    };

    return preparedData;
  };

  modifyData = dialogData => {
    const { skuList } = this.props;
    const { skuBatchList, data } = this.state;
    const selectedSku = filterItems(skuList, dialogData.skuId)[0] || {};
    const selectedBatch = filterItems(skuBatchList, dialogData.skuBatchId)[0] || {};

    return updateSkuLine(dialogData, selectedSku, selectedBatch, dialogData.amountDetails);
  };

  calculateTotal = (filteredList, updatedTotalObj) => {
    const totalAmount = { ...updatedTotalObj };
    const discounts = {
      billDiscount: totalAmount.billDiscount || 0,
      tradeDiscount: totalAmount.tradeDiscount || 0,
    };

    const discountAmount =
      Number(updatedTotalObj.promotionDiscount) +
      Number(updatedTotalObj.topUpDiscount) +
      Number(discounts.billDiscount) +
      Number(discounts.tradeDiscount);
    const taxableAmount = totalAmount.subTotal - Number(discounts.billDiscount) - discounts.tradeDiscount || 0;
    const vat = VAT_AMOUNT * taxableAmount;

    totalAmount.billDiscount = discounts.billDiscount || 0;
    totalAmount.tradeDiscount = discounts.tradeDiscount || 0;
    totalAmount.discountAmount = discountAmount || 0;
    totalAmount.taxAmount = vat;
    totalAmount.taxableAmount = taxableAmount;
    totalAmount.netAmount = taxableAmount + vat;
    this.setState({ totalAmount });
  };

  calculateLineTotal = () => {
    const calculatedAmounts = {
      subTotal: 0,
      promotionDiscount: 0,
      topUpDiscount: 0,
      grossAmount: 0,
      billDiscount: 0,
      tradeDiscount: 0,
    };
    const { data, totalAmount } = this.state;
    const selectedOrderDetails = this.getSelectedOrders(data.orders);
    selectedOrderDetails.forEach(sku => {
      calculatedAmounts.subTotal += sku.amountDetails.subTotal;
      calculatedAmounts.promotionDiscount += sku.amountDetails.promotionDiscount;
      calculatedAmounts.topUpDiscount += sku.amountDetails.topUpDiscount;
      calculatedAmounts.grossAmount += sku.amountDetails.grossAmount;
      calculatedAmounts.billDiscount += sku.amountDetails.billDiscount;
      calculatedAmounts.tradeDiscount += sku.amountDetails.tradeDiscount;
    });

    totalAmount.subTotal = calculatedAmounts.subTotal;
    totalAmount.promotionDiscount = calculatedAmounts.promotionDiscount;
    totalAmount.topUpDiscount = calculatedAmounts.topUpDiscount;
    totalAmount.grossAmount = calculatedAmounts.grossAmount;
    totalAmount.billDiscount = calculatedAmounts.billDiscount;
    totalAmount.tradeDiscount = calculatedAmounts.tradeDiscount;
    this.calculateTotal(selectedOrderDetails, totalAmount);
  };

  getCheckboxStatus = orderId => {
    const { selectedOrders } = this.state;
    return selectedOrders.indexOf(orderId) > -1;
  };

  getValidationStatus = () => {
    const { displayAlert } = this.props;
    const lineValidation = this.linesValidationStatus();
    if (!refValidator(totalViewRefs)) {
      return displayAlert(ALERT_TYPE.CUSTOM_DANGER, ERROR.NO_NEGATIVE);
    }
    return lineValidation && refValidator(totalViewRefs);
  };

  linesValidationStatus = () => {
    const { displayAlert } = this.props;
    const { selectedOrders } = this.state;
    const status = selectedOrders.length > 0;
    if (!status) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, ERROR.NO_ORDER_SELECTED);
    }
    return status;
  };

  determineReturnType = entireOrders => {
    const { selectedOrders } = this.state;
    if (selectedOrders.length === entireOrders.length) {
      const srnType = entireOrders.some(item => item.quantity !== item.returnQuantity)
        ? RETURN_TYPE.PARTIAL
        : RETURN_TYPE.COMPLETE;

      return srnType;
    }

    return RETURN_TYPE.PARTIAL;
  };

  getSelectedOrders = orders => {
    const { selectedOrders } = this.state;

    return orders.filter(order => selectedOrders.indexOf(order.id) > -1) || [];
  };

  exportData = () => {
    const { data, totalAmount } = this.state;
    const returnOrders = this.getSelectedOrders(data.orders);
    const srnType = this.determineReturnType(data.orders);

    return {
      srnType,
      returnOrders,
      totalAmount,
      orders: data.orders,
    };
  };

  updateState = (states = {}) => {
    this.setState({ ...states });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  render() {
    const { data, dialog, skuBatchList, totalAmount, selectedOrders } = this.state;
    const { type } = dialog;
    const { permission, skuList, enableErrorDisplay, serverResponseWaiting } = this.props;
    const isOldBill = data?.isOldBill || false;
    const skuLines = data.orders || [];
    const priceDetails = totalAmount || {};
    return (
      <TableStyled>
        <div className="return-create">
          {type && (
            <DialogFormWrapper
              formConfig={formConfig[type]}
              dialogElement={dialog.element}
              onDialogSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              type={type}
              renderDialog={({
                refsObj,
                dialogData,
                handleInputChange,
                enableErrorDisplay,
                handleMultipleUpload,
                handleDropDownChange,
                updateState,
                getState,
              }) => (
                <Fragment>
                  {(type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                    <Form
                      show
                      pending
                      type={type}
                      refsObj={refsObj}
                      skuList={skuList}
                      data={dialogData}
                      skuBatchList={skuBatchList}
                      stockTypeList={STOCK_TYPE_LIST}
                      loading={serverResponseWaiting}
                      getState={getState}
                      updateState={updateState}
                      handleInputChange={handleInputChange}
                      enableErrorDisplay={enableErrorDisplay}
                      dropDownCallBack={this.onDropDownChange}
                      inputCallBack={this.handleQtyChange}
                      handleDropDownChange={handleDropDownChange}
                      handleMultipleUpload={handleMultipleUpload}
                      invoiceNumberStatus
                      isOldBill={isOldBill}
                    />
                  )}
                  {type === EVENT_OPERATION.READ && <PromotionForm data={dialogData} loading={serverResponseWaiting} />}
                </Fragment>
              )}
            />
          )}
          <TableView
            isOldBill={isOldBill}
            permission={permission}
            skuLines={skuLines}
            checkedList={selectedOrders}
            totalOrders={skuLines.length}
            onIconClick={this.handleIconClick}
            checkBoxStatus={this.getCheckboxStatus}
            onPrimaryCheckBoxClick={this.handlePrimaryCheckBoxClick}
            onSecondaryCheckBoxClick={this.handleSecondaryCheckboxClick}
          />

          <TotalView
            isOldBill={isOldBill}
            refsObj={totalViewRefs}
            priceDetails={priceDetails}
            enableErrorDisplay={enableErrorDisplay}
            onInputChange={this.handleDiscountChange}
          />
        </div>
      </TableStyled>
    );
  }
}

Table.propTypes = propTypes;

Table.defaultProps = defaultProps;
export default withAlert()(Table);
